<template>
    <Toast />
    <div class="card">
        <div class="block sm:flex align-items-center justify-content-between mb-4">
            <h5 class="mb-3 sm:mb-0">Detail Customer</h5>
            <div v-if="!is_customer_list && data.flag_upgrade === 1">
                <Button
                    class="p-button btn-green mr-3"
                    style="width: auto"
                    @click="verifyCustomer()"
                    :disabled="form.loading">
                    <i class="pi pi-check mr-2" v-if="!form.loading"></i>
                    <i class="pi pi-spin pi-spinner mr-2" v-else></i>
                    Verifikasi
                </Button>
            </div>
        </div>

        <div class="grid">
            <!-- <div class="col-12 md:col-4 mb-4 pr-4">
                <img src="~@/assets/images/dummy/dummy.jpg" alt="" class="w-full border-round-lg">
            </div> -->
            <div class="col-12 md:col-8">
                <h6 class="text-black roboto-medium">Biodata</h6>
                <hr>
                <div class="overflow-auto mb-5">
                    <table class="table">
                        <tbody>
                            <tr v-for="(value, key) in biodata" :key="`biodata_${key}`">
                                <td>{{ value }}</td>
                                <td>
                                    <Skeleton width="100px" v-if="loading"/>
                                    <span v-else>{{ data[key] ? data[key] : '-' }}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <h6 class="text-black roboto-medium">Akun</h6>
                <hr>
                <div class="overflow-auto mb-5">
                    <table class="table">
                        <tbody>
                            <tr v-for="(value, key) in account" :key="`account_${key}`">
                                <td>{{ value }}</td>
                                <td>
                                    <Skeleton width="100px" v-if="loading"/>
                                    <span v-else>
                                        <template v-if="key === 'created_at'">
                                            {{ moment(data[key]).locale('id_ID').format('DD MMMM YYYY HH:mm') }}
                                        </template>
                                        <template v-else-if="key !== 'level'">
                                            {{ data[key] ? data[key] : '-' }}
                                        </template>
                                        <template v-else-if="key === 'flag_upgrade'">
                                            <Tag
                                                :class="flag_upgrade[data[key]].class"
                                                :severity="flag_upgrade[data[key]].class"
                                                :value="flag_upgrade[data[key]].label"
                                                :icon="flag_upgrade[data[key]].icon ?? null">
                                            </Tag>
                                        </template>
                                        <template v-else>
                                            <Tag class="mr-2 bg-linear-secondary border-round-xl" value="Premium" v-if="data[key] && data[key] === 'PREMIUM'"></Tag>
                                            <Tag class="mr-2 border-gray border-round-xl bg-white text-black opacity-60" value="Regular" v-else></Tag>
                                        </template>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <h6 class="text-black roboto-medium">Alamat</h6>
                <hr>
                <div class="overflow-auto mb-5">
                    <table class="table">
                        <tbody>
                            <tr v-for="(value, key) in address" :key="`address_${key}`">
                                <td>{{ value }}</td>
                                <td>
                                    <Skeleton width="100px" v-if="loading"/>
                                    <span v-else>{{ data[key] ? data[key] : '-' }}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <h6 class="text-black roboto-medium" v-if="data.identity_document_url && data.selfie_document_url">
                    Dokumen
                </h6>
                <hr v-if="data.identity_document_url && data.selfie_document_url">
                <div class="grid">
                    <div class="col-12 sm:col-6 md:col-5 lg:col-4" v-if="data.identity_document_url">
                        <div class="border-round-lg border-gray text-center pb-3">
                            <div class="mx-1 mt-1 border-round-lg border-gray photo"
                                :style="{'backgroundImage': `url(${data.identity_document_url})`}"
                            >
                            </div>
                            <h6 class="my-2 text-14 text-black roboto-medium">
                                Foto {{ data.identity_document_name }}
                            </h6>
                            <hr class="mt-0 mb-3">
                            <a :href="data.identity_document_url" class="text-primary text-12 link flex align-items-center justify-content-center">
                                <i class="pi pi-download mr-2"></i>
                                Download
                            </a>
                        </div>
                    </div>
                    <div class="col-12 sm:col-6 md:col-5 lg:col-4" v-if="data.selfie_document_url">
                        <div class="border-round-lg border-gray text-center pb-3">
                            <div class="mx-1 mt-1 border-round-lg border-gray photo"
                                :style="{'backgroundImage': `url(${data.selfie_document_url})`}"
                            >
                            </div>
                            <h6 class="my-2 text-14 text-black roboto-medium">
                                Foto {{ data.selfie_document_name }} + Selfie
                            </h6>
                            <hr class="mt-0 mb-3">
                            <a :href="data.selfie_document_url" class="text-primary text-12 link flex align-items-center justify-content-center">
                                <i class="pi pi-download mr-2"></i>
                                Download
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['id'],
    data() {
        return {
            moment: require("moment-timezone"),
            loading: false,
            data: {},
            biodata: {
                id: 'ID User',
                identity_number: 'NIK',
                name: 'Nama',
                birthplace:	'Tempat Lahir',
                birthdate: 'Tanggal Lahir',
                religion: 'Agama',
                nationality: 'Kewarganegaraan',
                marriage_status: 'Statu Perkawinan',
                mother_name: 'Nama Gadis Ibu Kandung',
                phone: 'No. Telepon',   
            },
            account: {
                email: 'Email',
                username: 'Username',
                created_at: 'Tanggal Daftar',
                level: 'Member',
                flag_upgrade: 'Upgrade Status',
            },
            address: {
                address: 'Alamat',
                province: 'Provinsi',
                city: 'Kota/Kabupaten',
                district: 'Kecamatan',
            },
            is_customer_list: true,
            deny_confirmation: false,
            accept_confirmation: false,
            form: {
                title: '',
                message: '',
                loading: false,
            },
            form_accept: {
                title: 'Account Approved',
                message: 'Upgrade request approved',
                loading: false,
            },
            deny_reasons: [
                'Data KTP tidak sesuai',
                'Lainnya',
            ],
            flag_upgraded: {
                0: { label: 'Pending', type: 'tag', class: 'warning' },
                1: { label: 'Submited', type: 'tag', class: 'info' },
                2: { label: 'Upgraded', type: 'tag', class: 'success' },
                3: { label: 'Denied', type: 'tag', class: 'danger' },
            },
        }
    },
    mounted () {
        this.fetchLink()
        this.getData()
    },
    methods: {
        fetchLink() {
            let pathName = this.$route.name
            if (pathName === 'customer-list-detail') {
                this.is_customer_list = true
            } else if (pathName === 'customer-list-upgrade-detail') {
                this.is_customer_list = false
            }
        },
        getData() {
            this.loading = true
            this.$http
                .get(`${process.env.VUE_APP_API_URL}/customers/${this.id}`)
                .then(response => {
                    this.loading = false
                    this.data = response.data.data
                })
        },
        verifyCustomer() {
            this.form.loading = true
            this.$http
                .post(`${process.env.VUE_APP_API_URL}/customers/${this.id}/ekyc`)
                .then(response => {
                    this.form.loading = false
                    if (response.data.code !== 200) {
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Error!',
                            detail: response.data.message,
                            life: 3000
                        });
                        return
                    }
                    this.data.flag_upgrade = 2
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Success',
                        detail: response.data.message,
                        life: 3000
                    });
                })
                .catch(error => {
                    this.form.loading = false
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Error!',
                        detail: error.response.data.message,
                        life: 3000
                    });
                })
        },
        onChangeReason(event) {
            if (event.value !== 'Lainnya') {
                this.form.message = 'Lorem ipsum dolor amet'
            } else {
                this.form.message = ''
                setTimeout(function(){
                    document.getElementById("message").focus();
                }, 250);
            }
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables';

.table {
    td {
        color: $black;
        padding-top: .5rem;
        padding-bottom: .5rem;
    }
    tr td:first-child{
        width: 200px;
        opacity: .6;
        @media (max-width: 575px) {
            width: 45%;
        }
    }
}
.photo {
    width: calc(100% - 0.5rem);
    height: 190px;
    background-image: url('~@/assets/images/dummy/profile.png');
    background-repeat: no-repeat;
    background-size: contain;
}
</style>
